import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ModalNineContent = () => {
    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: false,
    };
    return (
        <div className="slideshow">
            <figure>
                {/* Project Details Starts */}
                <figcaption>
                    <h3>Premium Chairs</h3>
                    <div className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-2">
                            <i className="fa fa-file-text-o pr-2"></i>
                            <span className="project-label">Проект </span>:{" "}
                            <span className="ft-wt-600 uppercase">Интернет магазин</span>
                        </div>
                        {/* End .col */}

                        <div className="col-12 col-sm-6 mb-2">
                            <i className="fa fa-user-o pr-2"></i>
                            <span className="project-label">Клієнт </span>:{" "}
                            <span className="ft-wt-600 uppercase">Premiumchairs.com.ua</span>
                        </div>
                        {/* End .col */}

                        <div className="col-12 col-sm-6 mb-2">
                            <i className="fa fa-code pr-2"></i>
                            <span className="project-label">CMS </span>:{" "}
                            <span className="ft-wt-600 uppercase">OpenCart</span>
                        </div>
                        {/* End .col */}

                        <div className="col-12 col-sm-6 mb-2">
                            <i className="fa fa-external-link pr-2"></i>
                            <span className="project-label">Сайт </span>:{" "}
                            <span className="ft-wt-600 uppercase">
                                <a href="https://premiumchairs.com.ua" target="_blank" rel="noreferrer" > premiumchairs.com.ua</a>
                            </span>
                        </div>
                        {/* End .col */}
                    </div>
                    {/* End .row */}
                </figcaption>
                {/* Project Details Ends */}

                {/*  Main Project Content Starts */}
                <Slider {...settings}>
                    <div className="slide_item">
                        <img src="img/projects/project-9_1.webp" alt="slide 1"/>
                    </div>
                    <div className="slide_item">
                        <img src="img/projects/project-9_2.webp" alt="slide 2"/>
                    </div>
                    <div className="slide_item">
                        <img src="img/projects/project-9_3.webp" alt="slide 3"/>
                    </div>
                    <div className="slide_item">
                        <img src="img/projects/project-9_4.webp" alt="slide 4"/>
                    </div>
                </Slider>
                {/* Main Project Content Ends */}

                {/* Project Description Content Starts */}
                <div className="project_description">
                    <h4>Опис проекту</h4>
                    <p>Проект надійшов в роботу в 2020 році. Станом на сьогоднішній день, продовжуються роботи з підтримки проекту. За весь час роботи, було внесено велику кількість змін в функціонал проету. Було реалізовано функціонал інтеграції магазину з різноманітними сервісами, розроблено ряд вдосконалень клієнтської частини а також значну кількість вдосконалень адміністративної панелі ресурсу.</p>
                </div>
                {/* Project Description Content Ends */}

            </figure>
        </div>
        //  End .slideshow
    );
};

export default ModalNineContent;
