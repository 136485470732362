import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ModalOneContent = () => {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
  };
  return (
      <div className="slideshow">
        <figure>
          {/* Project Details Starts */}
          <figcaption>
            <h3>Mega Feed</h3>
            <div className="row open-sans-font">
              <div className="col-12 col-sm-6 mb-2">
                <i className="fa fa-file-text-o pr-2"></i>
                <span className="project-label">Проект </span>:{" "}
                <span className="ft-wt-600 uppercase">Модуль "Mega Feed"</span>
              </div>
              {/* End .col */}

              <div className="col-12 col-sm-6 mb-2">
                <i className="fa fa-user-o pr-2"></i>
                <span className="project-label">Клієнт </span>:{" "}
                <span className="ft-wt-600 uppercase">GetBrand Studio</span>
              </div>
              {/* End .col */}

              <div className="col-12 col-sm-6 mb-2">
                <i className="fa fa-code pr-2"></i>
                <span className="project-label">CMS </span>:{" "}
                <span className="ft-wt-600 uppercase">Open Cart</span>
              </div>
              {/* End .col */}
            </div>
            {/* End .row */}
          </figcaption>
          {/* Project Details Ends */}

          {/*  Main Project Content Starts */}
          <Slider {...settings}>
            <div className="slide_item">
              <img src="img/projects/project-1_1.webp" alt="slide 1"/>
            </div>
            <div className="slide_item">
              <img src="img/projects/project-1_2.webp" alt="slide 2"/>
            </div>
            <div className="slide_item">
              <img src="img/projects/project-1_3.webp" alt="slide 3"/>
            </div>
            <div className="slide_item">
              <img src="img/projects/project-1_4.webp" alt="slide 4"/>
            </div>
          </Slider>
          {/* Main Project Content Ends */}

          {/* Project Description Content Starts */}
          <div className="project_description">
            <h4>Опис проекту</h4>
            <p>Замовник - Українська веб студія. Після обговорення деталей проекту, стояло завдання, створити модуль для вивантаження товарів в маркетплейси Prom, Rozetka та Facebook. Загалом, для даної CMS існують досить непогані готові рішення, проте в даному випадку, клієнту необхідно було дуже гнучко налаштовувати вивантаження товарів. Крім того, логіка роботи самого магазину була достатньо змінена попереднім функціоналом, таким чином, готові рішення не могли гарантувати виконання необхідних функцій та належної стабільності.</p>
            <p>В результаті, на вимоги клієнта було реалізовано необхідний функціонал, що включає в себе можливість гнучко та массово редагувати параметри та властивості товару як ф фіді данних, так і безпосередньо в самому інтернет магазині. Модуль пройшов усі стадії тестування та був успішно інтегрований в інтернет магазин.</p>
          </div>
          {/* Project Description Content Ends */}

        </figure>
      </div>
      //  End .slideshow
  );
};

export default ModalOneContent;
