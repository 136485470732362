import React from "react";

const SocialShare = [
  {
    iconName: "fa fa-facebook",
    link: "https://www.facebook.com/maguto.tech/",
  },
  {
    iconName: "fa fa-telegram",
    link: "https://t.me/maguto_tech"
  },
  {
    iconName: "fa fa-instagram",
    link: "https://www.instagram.com/maguto.tech/"
  },
  {
    iconName: "fa fa-github",
    link: "https://github.com/maguto-tech"
  },
];

const Social = () => {
  return (
    <ul className="social list-unstyled pt-1 mb-5">
      {SocialShare.map((val, i) => (
        <li key={i}>
          <a href={val.link} target="_blank" rel="noreferrer">
            <i className={val.iconName}></i>
          </a>
        </li>
      ))}
    </ul>
  );
};

export default Social;
