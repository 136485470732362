import React from "react";

const ModalFiveContent = () => {
  return (
      <div className="slideshow">
        <figure>
          {/* Project Details Starts */}
          <figcaption>
            <h3>Telbot</h3>
            <div className="row open-sans-font">
              <div className="col-12 col-sm-6 mb-2">
                <i className="fa fa-file-text-o pr-2"></i>
                <span className="project-label">Проект </span>:{" "}
                <span className="ft-wt-600 uppercase">Онлайн чат</span>
              </div>
              {/* End .col */}

              <div className="col-12 col-sm-6 mb-2">
                <i className="fa fa-user-o pr-2"></i>
                <span className="project-label">Клієнт </span>:{" "}
                <span className="ft-wt-600 uppercase">Maguto</span>
              </div>
              {/* End .col */}

              <div className="col-12 col-sm-6 mb-2">
                <i className="fa fa-code pr-2"></i>
                <span className="project-label">CMS </span>:{" "}
                <span className="ft-wt-600 uppercase">OpenCart, Wordpress інші</span>
              </div>
              {/* End .col */}
            </div>
            {/* End .row */}
          </figcaption>
          {/* Project Details Ends */}

          {/*  Main Project Content Starts */}
          <img src="img/projects/project-5_1.webp" alt="Portolio" />
          {/* Main Project Content Ends */}

          {/* Project Description Content Starts */}
          <div className="project_description">
            <h4>Опис проекту</h4>
            <p>Даний проект є реалізацією власного проекту з функціоналом онлайн чату. Мені здалося не дуже зручним використовувати сторонні засоби реалізації онлайн чату (jivosite, binotel і т.д) Тому було принято рішення реалізувати онлайн чат з інтеграцією з популярним мессенджером Telegram. Завантаживши даний модуль, власник магазину отримує можливість спілкування з клієнтами власного інтернет магазину напряму з Telegram (клієнт звертаеться в чаті на сайті, а власник відповідає напряму з мессенджеру). Є можливість підключення декількох менеджерів, якщо необхідно, а також, реалізовано функціонал збереження сеансів чату, для контролю якості обслуговування менеджерів або вирішення інших питань.</p>
          </div>
          {/* Project Description Content Ends */}
        </figure>
      </div>
      //  End .slideshow
  );
};

export default ModalFiveContent;
