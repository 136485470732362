import React from "react";

const personalInfoContent = [
  { meta: "Ім'я", metaInfo: "Сергій", type: "span", link: "" },
  { meta: "Прізвище", metaInfo: "Maguto", type: "span", link: "" },
  { meta: "Вік", metaInfo: "28 Років", type: "span", link: "" },
  { meta: "Національність", metaInfo: "Українець", type: "span", link: "" },
  { meta: "Адреса", metaInfo: "м Харків", type: "span", link: "" },
  { meta: "Email", metaInfo: "main@maguto.tech", type: "a", link: "mailto:main@maguto.tech" },
  { meta: "Telegram", metaInfo: "@maguto_tech", type: "a", link: "https://t.me/maguto_tech" },
  { meta: "Телефон", metaInfo: "093 644-78-38", type: "a", link: "tel:+380936447838" },
];

const PersonalInfo = () => {
  return (
    <ul className="about-list list-unstyled open-sans-font">
      {personalInfoContent.map((val, i) => (
        <li key={i}>
          <span className="title">{val.meta}: </span>
          {val.type === "span" && (
              <span className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
                  {val.metaInfo}
              </span>
          )}
          {val.type === "a" && (
              <a className="personal_contact" target="_blank" rel="noreferrer" href={val.link}>
                  {val.metaInfo}
              </a>
          )}
        </li>
      ))}
    </ul>
  );
};

export default PersonalInfo;
