import React from "react";
import { useForm } from "react-hook-form";

const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data, e) => {
    let response = await fetch('/sendmail.php', {method: 'POST', body: JSON.stringify(data)});

    let result = await response.json();
    e.target.reset();
  };

  return (
    <>
      <form id="request_form" className="contactform" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="form-group">
              <input
                {...register("name", { required: true })}
                type="text"
                name="name"
                placeholder="Ваше ім'я"
              />
              {errors.name && errors.name.type === "required" && (
                <span className="invalid-feedback">Я повинен знати з ким спілкуюсь</span>
              )}
            </div>
          </div>
          {/* End .col */}

          <div className="col-12 col-md-6">
            <div className="form-group">
              <input
                {...register(
                  "email",
                  {
                    required: "Я повинен знати як з вами зв'язатись",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Введене значення не відповідає формату електронної пошти",
                    },
                  },
                  { required: true }
                )}
                type="email"
                name="email"
                placeholder="Ваш email"
              />
              {errors.email && (
                <span className="invalid-feedback">{errors.email.message}</span>
              )}
            </div>
          </div>
          {/* End .col */}

          <div className="col-12 col-md-12">
            <div className="form-group">
              <input
                {...register("subject", { required: true })}
                type="text"
                name="subject"
                placeholder="Тема звернення"
              />
              {errors.subject && (
                <span className="invalid-feedback">Я повинен розуміти, на яку тему спілкуватись.</span>
              )}
            </div>
          </div>
          {/* End .col */}

          <div className="col-12">
            <div className="form-group">
              <textarea
                {...register("message", { required: true })}
                name="message"
                placeholder="Опишіть чим я можу бути вам корисним"
              ></textarea>
              {errors.message && (
                <span className="invalid-feedback">Я повинен розуміти, чим я можу бути корисним.</span>
              )}
            </div>
          </div>
          {/* End .col */}

          <div className="col-12">
            <button type="submit" className="button">
              <span className="button-text">Відправити</span>
              <span className="button-icon fa fa-send"></span>
            </button>
          </div>
          {/* End .col */}
        </div>
      </form>

      {/* End contact */}
    </>
  );
};

export default Contact;
