import React from "react";

const ModalSevenContent = () => {
  return (
    <div className="slideshow">
      <figure>
        {/* Project Details Starts */}
        <figcaption>
          <h3>Shop Security</h3>
          <div className="row open-sans-font">
            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-file-text-o pr-2"></i>
              <span className="project-label">Проект </span>:{" "}
              <span className="ft-wt-600 uppercase">Оптимізація</span>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-user-o pr-2"></i>
              <span className="project-label">Клієнт </span>:{" "}
              <span className="ft-wt-600 uppercase">Shop Security</span>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-code pr-2"></i>
              <span className="project-label">CMS </span>:{" "}
              <span className="ft-wt-600 uppercase">OpenCart</span>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-external-link pr-2"></i>
              <span className="project-label">Сайт </span>:{" "}
              <span className="ft-wt-600 uppercase">
                <a href="https://shop-security.com.ua/" target="_blank" rel="noreferrer" > shop-security.com.ua</a>
              </span>
            </div>
            {/* End .col */}
          </div>
          {/* End .row */}
        </figcaption>
        {/* Project Details Ends */}

        {/*  Main Project Content Starts */}
        <img src="img/projects/project-7_1.webp" alt="Portolio" />
        {/* Main Project Content Ends */}

        {/* Project Description Content Starts */}
        <div className="project_description">
          <h4>Опис проекту</h4>
          <p>В рамках даного проекту було проведено оптимізацію сайту згідно з вимогами Core Web Vitals. До початку віконання робіт, заміри показували дуже погані результати, зокрема мобільна версія головної сторінки отримала лише 19 балів. Було реалізовано ряд вдосконалень, такі як оптимізація та ресайз зображення під конкретний пристрій користувача, чистка коду від надлишкових запитів до БД та бібліотек, тощо. Загалом, результатом клієнт залишився дуже задоволеним. Проект виконувався приблизно півтора роки тому, а показники швидкості досі на вищому рівні.</p>
        </div>
        {/* Project Description Content Ends */}
      </figure>
    </div>
    //  End .slideshow
  );
};

export default ModalSevenContent;
