import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ModalTwoContent = () => {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
  };
  return (
      <div className="slideshow">
        <figure>
          {/* Project Details Starts */}
          <figcaption>
            <h3>Мега Акції</h3>
            <div className="row open-sans-font">
              <div className="col-12 col-sm-6 mb-2">
                <i className="fa fa-file-text-o pr-2"></i>
                <span className="project-label">Проект </span>:{" "}
                <span className="ft-wt-600 uppercase">Модуль "Мега Акції"</span>
              </div>
              {/* End .col */}

              <div className="col-12 col-sm-6 mb-2">
                <i className="fa fa-user-o pr-2"></i>
                <span className="project-label">Клієнт </span>:{" "}
                <span className="ft-wt-600 uppercase">GetBrand Studio</span>
              </div>
              {/* End .col */}

              <div className="col-12 col-sm-6 mb-2">
                <i className="fa fa-code pr-2"></i>
                <span className="project-label">CMS </span>:{" "}
                <span className="ft-wt-600 uppercase">Open Cart</span>
              </div>
              {/* End .col */}
            </div>
            {/* End .row */}
          </figcaption>
          {/* Project Details Ends */}

          {/*  Main Project Content Starts */}
          <Slider {...settings}>
            <div className="slide_item">
              <img src="img/projects/project-2_1.webp" alt="slide 1"/>
            </div>
            <div className="slide_item">
              <img src="img/projects/project-2_2.webp" alt="slide 2"/>
            </div>
            <div className="slide_item">
              <img src="img/projects/project-2_3.webp" alt="slide 3"/>
            </div>
            <div className="slide_item">
              <img src="img/projects/project-2_4.webp" alt="slide 4"/>
            </div>
            <div className="slide_item">
              <img src="img/projects/project-2_5.webp" alt="slide 5"/>
            </div>
            <div className="slide_item">
              <img src="img/projects/project-2_6.webp" alt="slide 6"/>
            </div>
            <div className="slide_item">
              <img src="img/projects/project-2_7.webp" alt="slide 7"/>
            </div>
          </Slider>
          {/* Main Project Content Ends */}

          {/* Project Description Content Starts */}
          <div className="project_description">
            <h4>Опис проекту</h4>
            <p>Замовник - Українська веб студія. Клієнт студії - мав власні акційні логіки і готові рішення для виконання поставлених задач не підходили. Тому веб студією було розроблено детальне ТЗ в якому було описано всі деталі проекту. Мною було розроблено весь функціонал даного рішення з урахуванням усіх нюансів розробки для даної CMS. На виконання даного проекту було витрачено тиждень. Разом з працівниками студії було протестовано та налагоджено роботу даного модуля та врешті, інтегровано в інтернет магазин.</p>
          </div>
          {/* Project Description Content Ends */}

        </figure>
      </div>
      //  End .slideshow
  );
};

export default ModalTwoContent;
