import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ModalThreeContent = () => {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
  };

  return (
    <div className="slideshow">
      <figure>
        {/* Project Details Starts */}
        <figcaption>
          <h3>Тайландська медицина</h3>
          <div className="row open-sans-font">
            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-file-text-o pr-2"></i>
              <span className="project-label">Проект </span>:{" "}
              <span className="ft-wt-600 uppercase">Оптимізація</span>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-user-o pr-2"></i>
              <span className="project-label">Клієнт </span>:{" "}
              <span className="ft-wt-600 uppercase">Іван Петров</span>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-code pr-2"></i>
              <span className="project-label">CMS </span>:{" "}
              <span className="ft-wt-600 uppercase">Wordpress</span>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-external-link pr-2"></i>
              <span className="project-label">Сайт </span>:{" "}
              <span className="ft-wt-600 uppercase">
                  <a href="https://cth.co.th/" target="_blank" rel="noreferrer">cth.co.th</a>
                </span>
            </div>
            {/* End .col */}
          </div>
          {/* End .row */}
        </figcaption>
        {/* Project Details Ends */}

        {/*  Main Project Content Starts */}
        <Slider {...settings}>
          <div className="slide_item">
            <img src="img/projects/project-3_1.webp" alt="slide 1" />
          </div>
          <div className="slide_item">
            <img src="img/projects/project-3_2.webp" alt="slide 2" />
          </div>
        </Slider>
        {/* Main Project Content Ends */}

        {/* Project Description Content Starts */}
        <div className="project_description">
          <h4>Опис проекту</h4>
          <p>Чудовий приклад реалізації якісної оптимізації сайту. Клієнт - власник декількох інтернет ресурсів пов'язаних з медициною та спрямованих на азіатську аудиторію. Проект було реалізовано на CMS Wordpress за допомогою популярного плагіна Elementor та низки інших рішень. Власники подібних ресурсів знають як важко оптимізувати ресурси зі схожою конфігурацією, оскільки використовуєть велика кількість сторонніх скриптів та бібліотек. Реалізація оптимізації дуже проста та надхвичайно складна одночас. </p><p>Простою тут являється ідея, що полягає в поступовому фоновому завантаженні необхідних скриптів після завантаження усіх критичних ресурсів. А ось сама реалізація досить складна, оскільки потрібно досконало розібрати кожну функцію та налаштувати прослуховуючі функції на необхідні дії.</p>
        </div>
        {/* Project Description Content Ends */}
      </figure>
    </div>
    //  End .slideshow
  );
};

export default ModalThreeContent;
