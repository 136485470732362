import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ModalEightContent = () => {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
  };
  return (
      <div className="slideshow">
        <figure>
          {/* Project Details Starts */}
          <figcaption>
            <h3>Namuri</h3>
            <div className="row open-sans-font">
              <div className="col-12 col-sm-6 mb-2">
                <i className="fa fa-file-text-o pr-2"></i>
                <span className="project-label">Проект </span>:{" "}
                <span className="ft-wt-600 uppercase">Интернет магазин</span>
              </div>
              {/* End .col */}

              <div className="col-12 col-sm-6 mb-2">
                <i className="fa fa-user-o pr-2"></i>
                <span className="project-label">Клієнт </span>:{" "}
                <span className="ft-wt-600 uppercase">Дмитро Петровський</span>
              </div>
              {/* End .col */}

              <div className="col-12 col-sm-6 mb-2">
                <i className="fa fa-code pr-2"></i>
                <span className="project-label">CMS </span>:{" "}
                <span className="ft-wt-600 uppercase">OpenCart</span>
              </div>
              {/* End .col */}

              <div className="col-12 col-sm-6 mb-2">
                <i className="fa fa-external-link pr-2"></i>
                <span className="project-label">Сайт </span>:{" "}
                <span className="ft-wt-600 uppercase">
                                <a href="https://namuri.com.ua" target="_blank" rel="noreferrer" > namuri.com.ua</a>
                            </span>
              </div>
              {/* End .col */}
            </div>
            {/* End .row */}
          </figcaption>
          {/* Project Details Ends */}

          {/*  Main Project Content Starts */}
          <Slider {...settings}>
            <div className="slide_item">
              <img src="img/projects/project-8_1.webp" alt="slide 1"/>
            </div>
            <div className="slide_item">
              <img src="img/projects/project-8_2.webp" alt="slide 2"/>
            </div>
            <div className="slide_item">
              <img src="img/projects/project-8_3.webp" alt="slide 3"/>
            </div>
            <div className="slide_item">
              <img src="img/projects/project-8_4.webp" alt="slide 4"/>
            </div>
            <div className="slide_item">
              <img src="img/projects/project-8_5.webp" alt="slide 4"/>
            </div>
          </Slider>
          {/* Main Project Content Ends */}

          {/* Project Description Content Starts */}
          <div className="project_description">
            <h4>Опис проекту</h4>
            <p>Проект виконувався на замовлення з <a href="https://freelancehunt.com/project/pravki-na-sayt-opencart/927732.html" target="_blank" rel="noreferrer">Freelancehunt</a>. Розпочиналось все з незначних правок дизайну та верстки. З замовником вдало спрацювались та було виконано ще ряд реалізацій функціоналу. На теперішній час роботи над ресурсом мною не проводяться. </p>
          </div>
          {/* Project Description Content Ends */}

        </figure>
      </div>
      //  End .slideshow
  );
};

export default ModalEightContent;
