import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ModalSixContent = () => {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
  };
  return (
      <div className="slideshow">
        <figure>
          {/* Project Details Starts */}
          <figcaption>
            <h3>Premiumspace</h3>
            <div className="row open-sans-font">
              <div className="col-12 col-sm-6 mb-2">
                <i className="fa fa-file-text-o pr-2"></i>
                <span className="project-label">Проект </span>:{" "}
                <span className="ft-wt-600 uppercase">Интернет магазин</span>
              </div>
              {/* End .col */}

              <div className="col-12 col-sm-6 mb-2">
                <i className="fa fa-user-o pr-2"></i>
                <span className="project-label">Клієнт </span>:{" "}
                <span className="ft-wt-600 uppercase">Premiumspace</span>
              </div>
              {/* End .col */}

              <div className="col-12 col-sm-6 mb-2">
                <i className="fa fa-code pr-2"></i>
                <span className="project-label">CMS </span>:{" "}
                <span className="ft-wt-600 uppercase">OpenCart</span>
              </div>
              {/* End .col */}

              <div className="col-12 col-sm-6 mb-2">
                <i className="fa fa-external-link pr-2"></i>
                <span className="project-label">Сайт </span>:{" "}
                <span className="ft-wt-600 uppercase">
                  <a href="https://premiumspace.us" target="_blank" rel="noreferrer">premiumspace.us</a>
                </span>
              </div>
              {/* End .col */}
            </div>
            {/* End .row */}
          </figcaption>
          {/* Project Details Ends */}

          {/*  Main Project Content Starts */}
          <Slider {...settings}>
            <div className="slide_item">
              <img src="img/projects/project-6_1.webp" alt="slide 1"/>
            </div>
            <div className="slide_item">
              <img src="img/projects/project-6_2.webp" alt="slide 2"/>
            </div>
            <div className="slide_item">
              <img src="img/projects/project-6_3.webp" alt="slide 3"/>
            </div>
            <div className="slide_item">
              <img src="img/projects/project-6_4.webp" alt="slide 4"/>
            </div>
            <div className="slide_item">
              <img src="img/projects/project-6_5.webp" alt="slide 4"/>
            </div>
          </Slider>
          {/* Main Project Content Ends */}

          {/* Project Description Content Starts */}
          <div className="project_description">
            <h4>Опис проекту</h4>
            <p>Проект виконувався на протязі 6-ти місяців. Хоча проект реалізовано на готовому шаблоні, було виконано значний обсяг роботи. Найважливішим єтапом роботи стала реалізація синхронізації даних магазину з іншими ресурсами орієнтованими на різні країни світу. В налаштуваннях головного магазину було реалізовано функціонал внесення даних з усіма необхідними залежностями, наприклад валюта чи мова, тощо. Врешті, головний магазин використовується в якості майже повноцінної CRM системи з можливістю гнучкого управління дочірніми ресурсами.</p>
          </div>
          {/* Project Description Content Ends */}

        </figure>
      </div>
      //  End .slideshow
  );
};

export default ModalSixContent;
